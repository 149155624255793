import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IntertextListing = _resolveComponent("IntertextListing")!
  const _component_BackToTop = _resolveComponent("BackToTop")!
  const _component_ArticleContainer = _resolveComponent("ArticleContainer")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.byCategory.entries(), ([key, intertexts], index) => {
    return (_openBlock(), _createBlock(_component_ArticleContainer, {
      key: key,
      class: "showLinks my-3",
      id: `category_${index + 1}`
    }, {
      default: _withCtx(() => [
        _createElementVNode("h3", _hoisted_1, _toDisplayString(key  ?? ("(Noch keiner Kategorie zugeordnet)")), 1),
        (intertexts[0].supercategory)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.groupBySubcategory(intertexts), ([k, subIntertexts]) => {
              return (_openBlock(), _createElementBlock("div", {
                key: k,
                class: "my-3"
              }, [
                _createElementVNode("h4", _hoisted_2, _toDisplayString(k), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subIntertexts, (i) => {
                  return (_openBlock(), _createBlock(_component_IntertextListing, {
                    key: i.id,
                    intertext: i
                  }, null, 8, ["intertext"]))
                }), 128))
              ]))
            }), 128))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(intertexts, (i) => {
              return (_openBlock(), _createBlock(_component_IntertextListing, {
                key: i.id,
                intertext: i
              }, null, 8, ["intertext"]))
            }), 128)),
        _createVNode(_component_BackToTop)
      ]),
      _: 2
    }, 1032, ["id"]))
  }), 128))
}